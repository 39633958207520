body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #152D35;
  color: gainsboro;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

header > h1 {
  font-size: 1.75em;
  font-variant: small-caps;
  padding-top: 0.5em;
}

header > h2 {
  font-size: 1em;
}

#wrapper {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
}

#markdown-container {
  background-color: #152D35;
}

#markdown-container > div {
  background-color: #152D35;
}

footer {
  font-size: smaller;
  padding-top: 0.5em;
}

footer > a {
  color: gainsboro;
}

footer > a:hover {
  color: #D4ECDD;
}
