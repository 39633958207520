#preview {
    background-color: gainsboro;
    border-radius: 10px;
    color: #152D35;
    height: 500px;
    overflow: auto;
    text-align: left;
    padding: 27px;
}

#preview img {
    max-width: 90%;
    display: block;
    margin: 2rem auto;
}

#preview pre {
    border-radius: 10px;
    background-color: rgb(40,44,52);
    padding: 25px;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size: 0.75em;
    color: gainsboro;
}

#preview blockquote {
    border-left: 3px solid rgb(40,44,52);
    color: rgb(40,44,52);
    padding-left: 5px;
    margin-left: 25px;
}

#preview ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
